@font-face {
    font-family: 'YourFontName';
    src: url('./fonts/InterstateB.otf') format('opentype');
    /* You may also need to specify other font formats such as 'woff' and 'woff2' for better cross-browser compatibility */
    /* Example:
       src: url('../fonts/your-font-file.otf') format('opentype'),
            url('../fonts/your-font-file.woff2') format('woff2'),
            url('../fonts/your-font-file.woff') format('woff');
    */
  }

  body {
    font-family: 'YourFontName', sans-serif;
  }