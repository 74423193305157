.game-box {
  /* width: 550px; */
  margin: auto;
  width: 50%;
}

.reset-btn {
  display: none;
}

.sc-EHOje {

}
.hsHcqX {
  
}
.start-btn {
  background: #fff;
  margin: auto;
  padding-bottom: 6px;
  padding-top: 6px;
  margin-top: 20px;
  border-radius: 8px;
  width: 140px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}


.reward {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 10%;
  width: 100vw;
  /* margin-top: -250px;*/
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-close {
  margin-top: 50px;
  font-family: inherit;
  font-size: 20px;
  padding: 10px 30px;
  border: 1px solid black;
  background: #fff;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s;
  transition-timing-function: linear;
}

.btn-close:hover {
  background: black;
  color: white;
}

.pointer {
  background-repeat: no-repeat;
  background-size: 30% 30%;
  /* left: 0; */
  padding-bottom: 100%;
  position: absolute;
  height: 30%;
  top: -40%;
  left: 11%;
  width: 76%;
  background-position: center center;
    
}